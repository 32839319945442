import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import Logo from "./logo.png";

const EmailForm = ({ onEmailSubmit }) => {
    const [email, setEmail] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        onEmailSubmit(email);
    };

    return (
        <form onSubmit={handleSubmit}>
            <img src={Logo} alt="Your SVG" class="mainLogo" />
            <div class="inner">
                <h1>Your SneakyTools Contract</h1>
                <p>Your active contract will be sent to your email address. If you experience any issues accessing your contract, please do not hesitate to contact us at the email address provided below.</p>
                <TextField
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                    variant="outlined"
                />
                <Button type="submit" variant="contained" color="primary">
                    Submit
                </Button>
            </div>

            <a href="mailto:hello@mangosolutions.io" class="contact">hello@mangosolutions.io</a>
        </form>

    );
};

export default EmailForm;
