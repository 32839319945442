import React from 'react';
import Logo from "./logo.png";

const SuccessPage = () => (
    <div>
        <img src={Logo} alt="Your SVG" class="mainLogo" />

        <div class="inner">
            <h1 class="aligned">Your submission was successful</h1>
            <p class="aligned">Please wait for an email from us.</p>
            <a href="/" class="contact">Back to Homepage</a>

        </div>

        <a href="mailto:hello@mangosolutions.io" class="contact">hello@mangosolutions.io</a>
    </div>
);

export default SuccessPage;
