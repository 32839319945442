import React, { useState } from 'react';
import EmailForm from './EmailForm';
import SuccessPage from './SuccessPage';
import { Container, Box } from '@mui/material';

const App = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleEmailSubmit = async (email) => {
    try {
      fetch('/api/web', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      })
      setSubmitted(true);
    } catch (error) {
      console.error('Failed to submit email: ', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          mt: 3
        }}
      >
        {submitted ? <SuccessPage /> : <EmailForm onEmailSubmit={handleEmailSubmit} />}
      </Box>
    </Container>
  );
};

export default App;
